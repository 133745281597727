<template>
    <div class="container mt-5 mx-auto col-4">
        <div class="card border-primary text-center">
            <div class="card-header">
                <h2><i :class="icon"></i> {{title}}</h2>
            </div>
            <div class="card-body">               
              <img src="../assets/avatar.png" alt="logo">
              <hr>
              <h4 class="card-title">{{subtitle}}</h4>
              <p>{{author}} &copy; {{gestion}}</p>
            </div>
        </div> 
    </div>    
</template>
<script>

export default { 
  created(){
    this.title = process.env.VUE_APP_TITLE;
    this.subtitle=process.env.VUE_APP_SUBTITLE;
    this.icon = process.env.VUE_APP_ICON;
    this.author = process.env.VUE_APP_AUTHOR;
    this.gestion = new Date().getFullYear();

  }
}
</script>
